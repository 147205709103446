import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import config from '../utils/config'
import alterraStore from '../../content/images/alterra-store.png'

export default function ProjectsIndex() {
  return (
    <Layout>
      <Helmet title={`Projects | ${config.siteTitle}`} />
      <SEO />
      <div className="page">
        <header>
          <div className="container">
            <h1>🔬 Projects.</h1>
            <p className="subtitle">
              Projek publik yang pernah aku kerjakan selama ini, bisa dilihat semua di {' '}
              <a href="https://github.com/ubaidillahhf">GitHub</a>.
            </p>
          </div>
        </header>

        <div className="container">
          <section>
            <h2>Alterra Store</h2>
            <h4 className="no-underline">
              Simple rest API with Golang, use echo framework.
            </h4>
            <p>
              <small>
                <i>
                  2021 &mdash; Golang, Echo Framework, Golang Validation, Gorm, JWT, Gin, mySql
                </i>
              </small>
            </p>
            <a
              href="https://github.com/ubaidillahhf/alterra-store"
              className="link-image"
              target="_blank"
            >
              <img src={alterraStore} />
            </a>
            <p>
              Projek ini saya buat sebenernya dulu digunakan untuk projek akhir bootcamp Alterra ACP 10, sangat jauh dari
              kata sempurna (masih REST API basic). Kedepan kemungkinan akan saya mantain dengan menambahkan fitur secara lengkap dan kompleks.
            </p>
            <p>
              Fitur dari REST API ini adalah: Register & Login, CRUD User, CRUD Kategori Produk, CRUD Produk, CRUD Shopping Cart, CRUD Transaksi.
            </p>

            {/* <Link to="/building-takenote" className="button">
              Write-up
            </Link> */}
            <a
              href="https://github.com/ubaidillahhf/alterra-store"
              className="button"
            >
              Source
            </a>
            <a href="https://github.com/ubaidillahhf/alterra-store" className="button">
              Demo
            </a>
          </section>

          <section>
            <h2>Pet Finder</h2>
            <h4 className="no-underline">
              Single page pet finder with react and parcel bundler.
            </h4>
            <p>
              <small>
                <i>2019 &mdash; ReactJs, Parcel Bundler</i>
              </small>
            </p>
            <p>
              Projek ini dibuat sebenernya karena ikutin workshop atau course dari frontendmaster.com, membuat aplikasi
              pencarian hewan peliharaan yang hilang.
            </p>
            <p>
              di projek ini saya pertama kali belajar apa itu ReactJs, apa itu SPA (Single Page Application), membuat app
              from sracth tanpa create react app kemudian dibungkus menggunakan bundler parce (seperti webpack) dengan bantuan
              babel tentunya yang berguna untuk mencompile kode kita dari JS terbaru ke kode lama yg disupport hampir semua browser.
            </p>
            
            <a href="https://github.com/ubaidillahhf/petapp-react" className="button">
              Source
            </a>
            <a href="https://github.com/ubaidillahhf/petapp-react" className="button">
              Demo
            </a>
          </section>

          <section>
            <h2>React Tail</h2>
            <h4 className="no-underline">
              Boilerplate react with tailwind and parcel bundler.
            </h4>
            <p>
              <small>
                <i>2020 &mdash; ReactJs, Tailwind, dan Parcel Bundler</i>
              </small>
            </p>
            <p>
              Projek ini sebenrnya digunakan sebagai boilerplate pribadi untuk membuat app reactjs dengan tailwind (semacam bootstrap) yang
              di bundler dengan parcel.
            </p>
            <p>
              Banyak belajar tentang implementasi tailwind.
            </p>
            <a href="https://github.com/ubaidillahhf/starter-reactTail" className="button">
              Source
            </a>
            <a href="https://github.com/ubaidillahhf/starter-reactTail" className="button">
              Demo
            </a>

            <h2>NFT Marketplace</h2>
            <h4 className="no-underline">
              My research about create marketplace NFT.
            </h4>
            <p>
              <i>2021 &mdash; ReactJs, Truffle, Web3, Ganache, Metamask, Solidity, Blockhain</i>
            </p>
            <p>
              Saya membuat projek ini sebenernya penasaran dan akhirnya research cara membuat NFT Marketplace
              di environtment ethereum dengan membuat kontrak dengan bahasa soliidity.
            </p>
            <p>
              Belajar banyak hal baru di dunia crypto currency terutama implementasi untuk NFT (Non Fungible Token).
            </p>
            <a
              href="https://github.com/ubaidillahhf/nft-eth"
              className="button"
            >
              Source
            </a>
            <a
              href="https://github.com/ubaidillahhf/nft-eth"
              className="button"
            >
              Demo
            </a>
          </section>
        </div>
      </div>
    </Layout>
  )
}
